const initialState = {
  options: {
    offices: [],
    zones: [],
    vias: [],
  },
};

const recaudaciones = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS':
      return { ...state, options: action.data };
    default:
      return { ...state };
  }
};

export default recaudaciones;
